export const GUEST_ENDPOINTS = {
  guestAssets: '/page/assets',
  guestBoxes: '/page/boxes',
  guestCheckoutSummary: '/page/checkout_summary',
  guestPallets: '/page/pallets',
  guestPickupLocation: '/page/pickup_location',
  guestPrice: '/page/price',
  guestRequestedPickup: '/page/requested_pickup',
  pageForToken: '/page',
}

export const ENDPOINTS = {
  account: '/account',
  address: '/address',
  alert: '/alert',
  assets: '/asset',
  comments: '/comment',
  company: '/company',
  dashboard: '/dashboard',
  destination: '/destination',
  error: '/error',
  file: '/file_upload',
  grid: '/grid',
  gridConfigs: '/grid_config',
  job: '/job',
  mixpanel: '/mixpanel',
  program: '/program',
  quote: '/quote',
  reportQueue: '/report_queue',
  reportSchedule: '/report_schedule',
  setting: '/setting',
  user: '/user',
}
