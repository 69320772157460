import * as React from 'react'
import Container from 'react-bootstrap/Container'
import { Helmet } from 'react-helmet'

import { Header } from 'components/Header'

import { Loader } from 'components/Loader'
import styles from './WizardPage.module.scss'

export type Props = {
  children?: React.ReactNode
  description?: string
  fitOnScreen?: boolean
  headerActions?: React.ReactNode
  hideFooter?: boolean
  hideHeader?: boolean
  isLoading?: boolean
  title?: string
}

export const WizardPage = ({
  children,
  description = 'OnePak Partner Community',
  isLoading,
  title = 'ReturnCenter Community',
}: Props): JSX.Element => (
  <div className={styles.Page}>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>

    <Header />

    <Container fluid className={styles.Container}>
      {isLoading ? (
        <div className={styles.LoaderContainer}>
          <Loader />
        </div>
      ) : (
        children
      )}
    </Container>
  </div>
)
